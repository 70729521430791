@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@300;400;600;700;800&display=swap');
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";


*{
  font-family: 'Commissioner', sans-serif !important; }

body {
  margin: 0;
  font-family:  'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination {
  background-color: #fff !important;
  border-radius: 5px;
  margin: 10px 0px;

}

.pagination li.page-item a,
.pagination li.page-item.active a,
.pagination li.page-item.active a {
  background: transparent;
  color: #6b6162;
  border: none;
  padding: 9px 18px;
  font-weight: 600;
  font-size: 14px;
}

.pagination li.page-item.active a {
  background-color: #ff1a57;
  color: #fff;
}

.pagination li.page-item.active a:focus {
  box-shadow: none;
}

.css-nd2sew {
  z-index: 99999 !important;
}

@media screen and (min-width: 768px){
  .css-x41tu {
    margin-top: -293px !important ;
}
}
.css-u5y1gb{
  justify-content: space-between !important;
}
@media screen and (min-width: 1200px) {
  .css-ctirlu {
    margin-top: -108px !important;
}
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #ff1a57 !important;
}
.rangeslider .rangeslider__handle {
  background: #ff1a57 !important;
  /* border: 1px solid #ccc; */
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow:none !important;
}



.stripe_div {     display: flex;
  align-items: center;
  background: #ffebf0;}

  .stripe_div .stripe_card  {   
    width: 45%;
    margin: 30px auto;
    min-height: 612px;
    background-color: #fff;
    padding: 12px 30px ;
    border-radius: 20px;}

    
  .stripe_div .stripe_card h4 {font-size: 18px; font-weight: 700; }


  .stripe_form  label{ font-size: 15px;}

  .stripe_form  .form-control {  padding: 10px 10px;
    border: 1px solid #dbdbdb;
    box-shadow: -2px 2px 9px 0px #00000017;}

    .pricing_card .price_title {
      font-size: 20px;
      font-weight: 800;
      padding-bottom: 10px;
  }
     .pricing_card .plan .icon { background: #B5D779;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 20px;}

      .pricing_card .plan p {  text-align: left;  font-weight: 500;
        font-size: 15px;}




        .css-148ke37 {
          border: 1px solid #ccc;
          border-radius: 15px;
          padding: 20px 20px  !important;
      }
      .plan {
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
        min-height: 55px;
        font-size: 14px;
    }
    .plan p {
      text-align: left;
  }
  .css-1w1vbxm {
    flex-wrap: wrap;
    padding-bottom: 30px;
    padding-inline: 10px;
}

.css-mlwtwp {
  font-weight: 700 !important;
}
.desc {
  width: 100%;
  text-align: left;
  margin-top: 5px;
  padding: 5px 0 5px;
  font-weight: 600;
  border-top: 1px solid #ccc;
  font-size: 14px;
} 
.css-1w1vbxm {
  flex-wrap: wrap;
}
.css-1a6cfux {
  align-items: self-start;
  margin: 30px 0 0 0;
}
.css-ughb0i {
  margin: 0;
}
.css-1q3pvy0 {
  margin: 0;
}
.chakra-button.css-1c3d1kb:hover p {
  color: #ff1a57 !important;
}
.btn_primary {
  color: #fff ; 
  font-weight: 600;
background-color: rgb(255, 26, 87);
border-color: rgb(255, 26, 87);
padding: 13px 21px;
font-size: 16px;
display: block;
width: 100%;
margin-top: 12px;
margin-bottom: 23px;}

.css-1hb7zxy-IndicatorsContainer {
  display: flex;
  align-items: center;
}

/* updated subscription page */
.price_tbl thead tr th h3 {
  font-size: 28px;
}
.price_tbl tbody tr td:first-child h3 {
  font-size: 28px;
}
.price_tbl tbody tr td {
  padding: 15px;
}

.table th, .table td {
  vertical-align: middle;
  text-align: center;
}
.btn-select {
  background-color: #ff4b4b;
  color: white;
  font-weight: bold;
}

.price_tbl thead tr {
  border: 0;
}

.price_tbl thead tr:first-child th:first-child {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
}

.price_tbl thead tr th {
  background: #f2f2f2;
  border-top: 1px solid #000;
  padding: 22px;
  position: relative;
  border-right: 1px solid #000;
}
.price_tbl thead tr th.active {
  border-top: 4px solid #ff1857;
  border-left: 4px solid #ff1857;
  border-right: 4px solid #ff1857;
  color: #ff1857;
  background: #fff;
}
.price_tbl tbody tr:last-child td.active {
  border-left: 0 !important;
}
.price_tbl tbody tr:last-child td.active {
  border-top: 4px solid #ff1857 !important;
}
.price_tbl tbody tr td.active {
  z-index: 99;
  position: relative;
  border-right: 4px solid #ff1857;
  border-left: 4px solid #ff1857 !important;
}
.price_tbl tbody tr.ftr td.active {
  background: #ff1857;
  color: #fff;
  border-bottom: 1px solid #ff1857;
  border-top: 1px solid #ff1857;
}
.price_tbl tbody {
  border: 0 !important;
}
.price_tbl tbody tr td:first-child {
  background: #f2f2f2;
  color: inherit;
}
.price_tbl tr.ftr td {
  background: #000;
  color: #fff;
}

.price_tbl tbody tr:last-child td:first-child {
  background: transparent;
  border: 0;
}

.price_tbl tbody tr:last-child {
  border: 0;
}

.price_tbl tbody tr:last-child td {
  border: 0;
}
.price_tbl button.btn {
  background: #ff1857;
  border-radius: 12px;
  font-weight: 600;
  width: 150px;
  padding: 6px 25px;
}
.sub_spn {
  width: 1.3rem !important;
  height: 1.3rem !important;
}



/* contact us */
.contact_pg h3 {
  font-family: 'Rubik', sans-serif;
  color: #3b3538;
}
.contact_pg .form-control.form-control-lg {
  border: 1px solid #b1aba9 !important;
  border-radius: 5px;
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.8rem 1rem;
  font-size: 16px;
  border-radius: 0.3rem;
  font-family: 'Rubik', sans-serif;
}
.contact_pg .btn-primary {
  color: #fff;
  background-color: #ff1a57;
  border-color: #ff1a57;
  font-weight: 600;
  padding: 13px 21px;
  font-size: 16px;
}
.contact_pg label.form-label {
  margin-bottom: 0.5rem;
  color: #6b6162;
}
.contact_pg .input-group-text {
  color: #978d8c;
  border: 1px solid #b1aba9;
}
.logo p {
  font-size: 14px;
}


.add_frm {
  width: 100%;
  max-width: 350px;
}


.file-upload {
  background-color: #ffffff;
  width: 30%;
  margin: 0;
  padding: 0;
}
.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #1FB264;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #15824B;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #1AA059;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}



.image-upload-wrap {
  margin-top: 20px;
  border: 1px dashed #ff1a57;
  position: relative;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #fff7f9;
}



.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}


.notification-ui_dd-content {
  margin-bottom: 30px;
}


.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 7px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  overflow: hidden;
  border: none;
}

.notification-list--unread {
  border-left: 2px solid #FF1A57;
}

.notification-list .notification-list_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.notification-list .notification-list_content .notification-list_img img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 20px;
}

.notification-list .notification-list_content .notification-list_detail p {
  margin-bottom: 5px;
  line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  margin-left: 20px;
}

.clearNotification{
  display: flex;
  align-items: end;
  justify-content: end;
  margin-right: 12px;
  margin-bottom: 10px;

}