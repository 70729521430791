@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@300;400;500&family=Rubik:wght@400;500&display=swap');
body, html {
   font-family: 'Commissioner', sans-serif;
}
.faq_container h4 {
font-weight: 400;
font-size: 18px;
margin: 20px 0 16px;
}
.faq_container{
width:100%;
}
.mb-5 {
margin-bottom: 20px;
}
.faq_container p  {
font-size: 16px;
color: #3c3737;
font-weight: 400;
line-height: 1.7;
margin: 10px 0;
}
ol.list_style li {
font-size: 16px;
color: #3c3737;
font-weight: 400;
line-height: 1.4;
margin: 10px 0;
list-style-type:lower-alpha;
text-transform: capitalize;
}
ul.list_style li {
font-size: 16px;
color: #3c3737;
font-weight: 400;
line-height: 1.4;
margin: 10px 0;
list-style-type:lower-alpha;
text-transform: capitalize;
}
h1.text_dark {
font-size: 23px;
font-weight: 800;
line-height: 1.2;
text-align: center;
}
table, tr, td {
border: 1px solid rgb(82, 80, 80);
border-collapse: collapse;
}
table tr td{padding: 10px;}
table tr td:first-child{font-weight: 600;}
table tr:nth-child(odd) {background-color:rgb(239, 240, 239);}
.list_item{
list-style: number outside none;
display: list-item;
padding: 0px;
margin: 10px 23px !important;
}
table thead tr th {padding: 10px; background-color: #fff;}
.ul_spc{
   padding-left: 2rem;
}