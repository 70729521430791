.mrh_plans {
    padding: 60px 0;
    font-family: "Commissioner", sans-serif !important;
    background-color: #F8F8F8;
}
.glw_txt h2 {
    text-align: center;
    padding: 30px 0 50px;
    font-weight: 800;
    margin: 0;
    font-size: 36px;
    color: #1E0F42;
    text-shadow: 0px 0px 10px #00000042;
}
.mar_crds {
    width: 90%;
    background-color: #FFFFFFCC;
    border-radius: 20px;
    padding: 40px 30px;
    text-align: center;
    margin: 0 auto 20px;
    border: 3px solid transparent;
}
.mar_crds.active {
    border: 3px solid #FF1957;
}
.mar_crds .hed p {
    color: #FF1957;
    font-weight: 800;
    font-size: 24px;
}
.mar_crds .hed h3 {
    font-weight: 800;
    color: #1E0F42;
    margin: 0;
    padding: 0px 0 5px;
    font-size: 34px;
}
.mar_crds .desc {
    min-height: 250px;
    margin: 30px 0 0 0;
    border: 0;
}  
.mar_crds .desc p {
    font-size: 18px;
    font-weight: 500;
    color: #1E0F42;
    text-align: center;
}
.cta button {
    width: 70%;
    border: 1px solid #FF1A57;
    background-color: #FF1A57;
    padding: 25px;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}


@media screen and (max-width: 1000px) {
    .mrh_plans .container {
        max-width: 1200px;
        width: 95%;
    }
}

/* responsiveness */
@media screen and (max-width: 480px) {
    .mar_crds {
        width: 100%;
        padding: 30px 20px;
        margin: 0 auto 20px;
    }
    .mar_crds .hed h3 {
        font-size: 25px;
    }
    .mar_crds .hed p {
        font-size: 20px;
    }
    .cta button {
        width: 100%;
        padding: 15px 25px;
        border-radius: 10px;
    }
  }

  @media screen and (min-width:768px) and (max-width:1024px) {
    .mar_crds {
        padding: 12px 30px;
    }
    .cta button {
        margin-top: 15px;
        width: 100%;
    }
  }

  @media screen and (min-width: 1200px) and (max-width:1400px) {
    .mrh_plans {
        padding: 9px 0;
        }
        .glw_txt h2 {
            padding: 22px 0 25px;
        }
        .mar_crds {
            padding: 17px 10px;
            }
            .mar_crds .desc {
            min-height: 210px;
            }
}