:root {
    --main_color: #ff1957;
    --gray: #e8e6e3;
    --dark_blue: #1e0f42;
    --bg_gray: #eeeeee;
    --body_bg: #f8f8f8;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: "Commissioner", sans-serif !important;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--body_bg) !important;
  }
  
  /* header css */
  
  .landing_header .landing_nav .landing_menu li {
    background: #e8e6e3;
    border-radius: 32px;
    padding: 3px 10px;
    color: #3b3538;
  }
  
  .landing_header .landing_nav .landing_menu li:has(.active) {
    background-color: #ff1957;
    color: #fff;
  }
  
  .landing_header .landing_nav .landing_menu li a {
    color: inherit;
  }
  
  .landing_btn {
    background-color: var(--main_color);
    border: none;
    color: #fff;
    padding: 14px 78px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
  }
  
  /* banner css */
  
  .landing_banner .banner_img {
    background-image: url(/src/assets/images/banner_img.png);
    height: 550px;
    background-repeat: no-repeat;
    width: 100%;
    /* margin: 0 auto; */
    background-size: cover;
    background-position: center;
    /* border-radius: 42px; */
    border-radius: 14px;
    margin-top: 42px;
  }
  
  .landing_banner .banner_img .connect_with {
    background: #ffffff63;
    width: 95%;
    max-width: 400px;
    margin-right: 8%;
    border-radius: 21px;
    padding: 32px;
    text-align: center;
    backdrop-filter: blur(12px);
  }
  
  .landing_banner .banner_img .connect_with h4 {
    font-size: 33px;
    font-weight: 800;
    color: var(--dark_blue);
  }
  
  .landing_banner .banner_img .connect_with p {
    font-size: 18px;
    color: #1e0f42;
    font-weight: 500;
  }
  
  /* launch_offer section */
  
  .launch_offer {
    background-color: var(--main_color);
    padding: 60px 0;
  }
  .launch_offer .offer_img {
    /* width: 520px;
    height: 290px; */
    border-radius: 20px;
    overflow: hidden;
  }
  
  .launch_offer .offer_text h5 {
    font-size: 18px;
    font-weight: 800;
    color: #fff;
  }
  
  .launch_offer .offer_text h4 {
    font-size: 30px;
    font-weight: 800;
    color: #fff;
  }
  .launch_offer .offer_text h6 {
    font-weight: 800;
    font-size: 18px;
    color: #fff;
  }
  .launch_offer .offer_text p {
    font-size: 16px;
    color: #fff;
  }
  
  .launch_offer .offer_text {
    width: 90%;
    max-width: 360px;
  }
  
  /* why partner section */
  .why_partner h4 {
    font-weight: 800;
    font-size: 32px;
  }
  
  .why_partner .all_offers .offer_text h6 {
    font-size: 18px;
    font-weight: 800;
  }
  .why_partner .all_offers .offer_text p {
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }
  .why_partner .all_offers .offers {
    width: 29%;
  }
  .why_partner .all_offers {
    display: flex;
    grid-gap: 14px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .why_partner .all_offers .offer_icon {
    min-height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
  /* how it works */
  .hw_it_wrks {
    background-color: var(--bg_gray);
    padding: 40px 0;
  }
  .hw_it_wrks h2 {
    font-weight: 700;
    color: var(--main_color);
    font-size: 32px;
  }
  .hw_it_wrks h5 {
    font-weight: 700;
    color: var(--dark_blue);
    font-size: 26px;
}
.landing_banner .banner_img .connect_with img {
  margin: 0 auto;
}
  .hw_it_wrks .icn {
    height: 60px;
    width: 60px;
    display: inline-flex;
    background-color: var(--main_color);
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 700;
    font-size: 22px;
  }
  .hw_crd h3 {
    font-size: 22px;
  }
  
  .hw_crd p {
    font-size: 16px;
    font-weight: 500;
  }
  
  /* prime community */
  .roff_offrs {
    padding: 40px 0;
  }
  .prime_blk .crd {
    background-color: var(--main_color);
    padding: 60px 30px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
  }
  
  .prime_blk .crd .desc {
    max-width: 450px;
    margin: 0 auto;
    border: 0;
    text-align: center;
    font-size: 28px;
}
  .prime_blk .crd .btn_grp .lrn_mor {
    border: 1px solid #fff;
    color: #fff;
    padding: 12px;
    text-decoration: none;
    border-radius: 5px;
    min-width: 220px;
    display: inline-flex;
    justify-content: center;
    font-weight: 600;
    transition: 0.4s ease-in-out;
  }
  .prime_blk .crd .btn_grp .lrn_mor:hover {
    background-color: #fff;
    color: #333;
  }
  
  .landing_btn {
    background-color: var(--main_color);
    border: none;
    color: #fff;
    padding: 14px 78px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
  }
  .prime_blk .crd .btn_grp {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .prime_blk .crd .btn_grp .lrn_mor {
    border: 1px solid #fff;
    color: #fff;
    padding: 12px;
    text-decoration: none;
    border-radius: 5px;
    min-width: 220px;
    display: inline-flex;
    justify-content: center;
  }
  
  .prime_blk .landing_btn {
    background-color: #fff;
    color: #333;
    padding: 13px;
    min-width: 220px;
    display: inline-flex;
    justify-content: center;
    text-decoration: none;
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;
  }
  
  .prime_blk .landing_btn:hover {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
  }
  
  .landing_newsletter {
    padding: 60px 0;
  }
  
  .landing_newsletter .nws_blk {
    text-align: center;
  }
  
  .landing_newsletter .nws_blk h2 {
    color: var(--dark_blue);
    font-weight: 700;
    font-size: 32px;
}
  
  .landing_newsletter .nws_blk p {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
  }
  .itm_hd ul li {
    font-size: 18px;
}
  
  /* newsletter */
  .nws_frm {
    display: flex;
    justify-content: center;
  }
  
  .nws_frm input[type="email"] {
    padding: 10px 30px 10px 20px;
    border-radius: 10px;
    background-color: #eeeeee;
    border: 2px solid #cfcfcf;
    font-weight: 500;
    outline: none;
  }
  
  .nws_frm button {
    background-color: var(--main_color);
    border: 1px solid var(--main_color);
    padding: 10px 20px;
    border-radius: 0px 10px 10px 0px;
    margin-left: -20px;
    color: #fff;
  }
  
  /* footer */
  
  .fut_lst ul {
    gap: 30px;
    margin: 0;
    padding: 0;
  }
  
  .fut_logo {
    margin: 25px 40px 40px 0px;
  }
  .fut_lst ul li a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
}
  .fut_lst ul li:last-child {
    position: relative;
  }
  
  .fut_lst ul li:before {
    content: "";
    position: absolute;
    right: -20px;
    top: 0;
    height: 100%;
    width: 3px;
    background: #000;
  }
  
  .fut_lst ul li:last-child::before {
    width: 0;
  }
  .roff_offrs h2 {
    color: var(--main_color);
    font-weight: 700;
    font-size: 32px;
}
  .fut_lst li {
    list-style: none;
    position: relative;
}   
.list_fut li {
    list-style: none;
}
.prime_blk .crd .desc h3 {
  color: #fff;
  font-size: 29px;
}
.prime_blk .crd .desc h6 {
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.itm_hd li {
    list-style: none;
}
  
  
  
  /* responsiveness */
  @media screen and (max-width: 767px) {
    .roff_offrs .img{
        width: 90%;
        margin: 0 auto;
    }
    .roff_offrs .img img {
      max-width: 100%;
      height: auto;
  }
    .prime_blk .crd .btn_grp {
        flex-wrap: wrap;
  }
  .landing_banner .banner_img {
    justify-content: center !important;
  }
  .landing_banner .banner_img .connect_with {
    margin-right: 0%;
  }
  .launch_offer .offer_text {
    margin: 40px auto 0;
  }
  .why_partner .all_offers .offers {
    width: 100%;
  }
  .fut_lst ul li:before {
    width: 2px;
  }
  .fut_lst ul {
    gap: 10px 30px;
    flex-wrap: wrap;
    padding: 0 10px;
  }
  .why_partner .all_offers {
    width: 90%;
    margin: 0 auto;
  }
  .nws_frm form {
    text-align: center;
  }
  .nws_frm button {
    border-radius: 10px;
    margin-left: 0;
    margin-top: 10px;
  }
  .nws_frm input[type="email"] {
    padding: 10px 20px 10px 20px;
    text-align: center;
    width: 80%;
  }
  .fut_lst {
    margin-bottom: 30px;
  }
  .list_fut {
    justify-content: left !important;
  }
  .prime_blk .crd {
    padding: 20px 20px 40px;
  }
  .why_partner .all_offers .offer_text p {
    margin: 10px 0 20px;
  }
  .why_partner .all_offers .offer_text p:last-child {
    margin-bottom: 0;
  }
  .landing_banner .banner_img .connect_with {
    padding: 30px 20px;
}
  .landing_banner .banner_img .connect_with h4 {
    font-size: 28px;
  }
  .landing_header .landing_nav .landing_menu li {
    padding: 3px 15px;
    font-size: 14px;
  }
  .landing_header .navbar-nav {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .landing_header .navbar-toggler {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
  .landing_header .landing_btn {
    width: 100%;
  }
  .landing_header .navbar-nav {
    margin: 20px 0 !important;
  }
  .fut_wrap {
    padding-bottom: 40px;
  }
  .list_fut {
    justify-content: left !important;
    padding: 0 10px 20px !important;
}
.fut_logo {
    margin: 10px 40px 20px 15px;
}
  }